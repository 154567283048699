import React from "react";
import {
  CFormGroup,
  CLabel,
  CTextarea,
  CCol
} from "@coreui/react";
import Select from "react-select";
import { AddressService } from "src/_services";

export class AddressSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: [],
      province: null,
      districts: [],
      distric: null,
      address: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
  }
  componentDidMount() {
    this.buildValue()
    if (!this.isReadonly()) {
      this.getProvinces();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isContactChange !== this.props.isContactChange) {
      this.buildValue();
      this.getProvinces();
    }
  }
  handleChange(e) {
    let { value } = e.target;
    this.setState({ address: value });
    this.emitValue()
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.value !== this.props.value) {
  //     setTimeout(() => {
  //       // this.buildValue()
  //     }, 600)
  //   }
  // }
  applyCallback(value) {
    if (value && value.type === 'province') {
      const { province } = this.state
      if (province && province.value !== value.value) this.setState({ district: null })
      this.setState({ province: value })
      setTimeout(() => {
        this.getDistrict()
      }, 200);

    }
    if (value && value.type === 'district') {
      this.setState({ district: value })
    }
    this.emitValue()

  }
  emitValue() {
    setTimeout(() => {
      const { province, district, address } = this.state
      console.log(province, district, address)
      if (this.props.onChange)
        this.props.onChange({
          target: {
            name: this.props.name,
            value: [province ? province.value : '', district ? district.value : '', address]
          }
        });
    }, 300)
  }
  buildValue() {
    const { value } = this.props
    if (value) {
      this.setState({ province: value[0] ? { label: value[0], value: value[0] } : null })
      this.setState({ district: value[1] ? { label: value[1], value: value[1] } : null })
      this.setState({ address: value[2] || '' })
      setTimeout(() => {
        this.getDistrict()
      }, 200)
    }
  }
  getProvinces() {
    new AddressService().provinces().then(provinces => {
      this.setState({
        provinces: Object.keys(provinces).map((key) => {
          provinces[key].label = provinces[key].name
          provinces[key].value = provinces[key].name
          provinces[key].type = 'province'
          return provinces[key]
        }).sort((a, b) => (a.code > b.code && 1) || -1)
      })
    })
  }
  getProvinceCode (province) {
    if (province.code) return province.code
    const { provinces } = this.state
    var finded = provinces.find(e => e.name === province.value)
    return finded ? finded.code : ''
  }
  getDistrict() {
    const { province } = this.state
    if (!province) return
    let code = this.getProvinceCode(province)
    if (code) {
      new AddressService().noLoading().noError().districts(code).then(districts => {
        this.setState({
          districts: Object.keys(districts).map((key) => {
            districts[key].label = districts[key].name
            districts[key].value = districts[key].name
            districts[key].type = 'district'
            return districts[key]
          })
        })
      })
    }
  }
  isReadonly() {
    return this.props.readOnly
  }
  province() {
    const { province, provinces } = this.state
    return (
      <CFormGroup>
        <CLabel className="pr-10">Tỉnh/ thành phố:</CLabel>
        {this.isReadonly() ? (
          province ? province.label : ''
        ) : (
            <Select value={province || ''} options={provinces} isSearchable={true} onChange={(e) => this.applyCallback(e)} name="province" />
          )}
      </CFormGroup>
    )
  }
  district() {
    const { district, districts } = this.state
    return (
      <CFormGroup>
        <CLabel className="pr-10">Quận/ huyện:</CLabel>
        {this.isReadonly() ? (
          district ? district.label : ''
        ) : (
            <Select value={district || ''} options={districts} isSearchable={true} onChange={(e) => this.applyCallback(e)} name="district" />
          )}
      </CFormGroup>
    )
  }
  address() {
    const { address } = this.state
    return (
      <CFormGroup>
        <CLabel className="pr-10">Địa chỉ (Số nhà, đường, phố, phường):</CLabel>
        {this.isReadonly() ? (
          address
        ) : (
            <CTextarea
              name="shipping_address"
              id="shipping_address"
              rows="1"
              placeholder="Địa chỉ giao hàng"
              onChange={this.handleChange}
              value={address}
            />
          )}
      </CFormGroup>
    )
  }
  render() {
    return (<>
      <CFormGroup row>
        <CCol md="6">
          {this.address()}
        </CCol>
        <CCol md="3">
          {this.province()}
        </CCol>
        <CCol md="3">
          {this.district()}
        </CCol>
      </CFormGroup>
    </>)
  }
}