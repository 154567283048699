import BaseService from './base.service'
export class CustomService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}, enpoint) {
    super(`api/${enpoint}`, parameters)
  }
}
export class CustomApiService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}, enpoint) {
    super(`${enpoint}`, parameters)
  }
}