import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const suggestions = [];

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const TagInput = (props) => {
  const [tags, setTags] = React.useState(((props.value || '').split(',') || []).filter(e => e).map(e => {
    return {
      id: e,
      text: e
    }
  }));
  const emit = (newtag) => {
    setTimeout(() => {
      if (props.onChange)
        props.onChange({
          target: {
            name: props.name,
            value: newtag.filter(e => e.text).map(e => e.text).join(',')
          }
        })
    }, 100)
  }
  const handleDelete = i => {
    var newtag = tags.filter((tag, index) => index !== i)
    setTags(newtag);
    emit(newtag)
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
    emit([...tags, tag])
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
    emit(newTags)
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  return (
    <ReactTags
      tags={tags}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      handleTagClick={handleTagClick}
      placeholder='Nhập và ấn enter để thêm thông tin'
    />
  );
};