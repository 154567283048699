import BaseService from './base.service'

export class WarrantySolutionsService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/warranty/solution', parameters)
  }
}
