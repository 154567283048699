import BaseService from './base.service'

export class RegisterNetworkRequest extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/register_network_request', parameters)
  }
}

