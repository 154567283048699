export * from './alert.constants';
export * from './user.constants';
export * from './mesage.constants';
export * from './his.constants';
export * from './his.constants';
export * from './his.constants';
export * from './conditionType.constants';
export * from './validator.message';
export const storeInvoiceType = [
  'Phiếu nhập hàng', 'Phiếu xuất hàng', 'Phiếu điều chuyển', 'Phiếu hủy', 'Phiếu tạo tự động'
]
export const paymentTypes = [
  'Tiền Mặt', 'Chuyển Khoản', 'COD', 'Công Nợ'
]
export const orderStatus = [
  'Tạo mới', 'Đã gửi, Chờ duyệt', 'Đã duyệt', 'Đang giao', 'Đã nhận', 'Đã hủy'
]
export const orderType = [
  'Đơn bán hàng', 'Đơn đặt hàng', 'Đơn đặt hàng khách lẻ', 'Trả hàng', 'Đơn bán hàng khách lẻ',
]
export const submenuConstants = {
  ADD: 'ADD_MENU',
  NEW: 'NEW_MENU',
  CLEAR: 'CLEAR'
};
export const transactionType = [
  {value: '', label: '-- Chọn --'},
  {value: '0', label: 'Doanh thu'},
  {value: '1', label: 'Chi phí nhập hàng'},
  {value: '2', label: 'Chi phí ship'},
  {value: '3', label: 'Thuế'},
  {value: '4', label: 'Nợ'},
  {value: '5', label: 'Trả nợ'},
]
export const orderStatusOption = [
  {value: '', label: 'Tất cả'},
  {value: '1', label: 'Tạo mới'},
  {value: '2', label: 'Đã duyệt'},
  {value: '3', label: 'Đang giao'},
  {value: '4', label: 'Đã nhận'},
  {value: '5', label: 'Đã Hủy'}
]
export const postType = [
  {value: '0', label: 'Giới thiệu'},
  {value: '1', label: 'Chính sách đại lý'},
  {value: '2', label: 'Tin tức'},
  {value: '3', label: 'Hướng dẫn sử dụng'},
  {value: '4', label: 'Chính sách bảo hành'},
  {value: '5', label: 'Liên hệ'},
  {value: '6', label: 'Cơ sở bảo hành'}
]
export const itemPostType = [
  {value: '0', label: 'Bài viết thông thường quảng cáo sản phẩm'},
  {value: '1', label: 'Bài viết thông số sản phẩm'},
  {value: '2', label: 'Hướng dẫn sử dụng'},
  {value: '3', label: 'Chính sách bảo hành'},
  {value: '4', label: 'Liên hệ với chúng tôi'},
  {value: '5', label: 'Cơ sở bảo hành'}
]
export const WarrantyStatusOption = [
  {value: '', label: 'Tất cả'},
  {value: 'True', label: 'Đã kích hoạt'},
  {value: 'False', label: 'Chưa kích hoạt'}
]
export const WarrantyMalfunctionType = [
  {
    value: 0,
    label: '---'
  },
  {
    value: 1,
    label: 'Lỗi nhà SX'
  },
  {
    value: 2,
    label: 'Lỗi vận chuyển'
  },
  {
    value: 3,
    label: 'Khác'
  }
]
export const WarrantySolutionType = [
  {
    value: 0,
    label: '---'
  },
  {
    value: 1,
    label: 'Đổi sản phẩm mới'
  },
  {
    value: 2,
    label: 'Sửa chữa'
  },
  {
    value: 3,
    label: 'Hoàn tiền'
  }
]
export const WARRANTY_BOOK_STATUS = [
  {
    value: '',
    label: 'Tất cả'
  },
  {
    value: '0',
    label: 'Chưa kích hoạt'
  },
  {
    value: '1',
    label: 'Đã kích hoạt'
  },
  {
    value: 2,
    label: 'Đã tiếp nhận BH'
  },
  {
    value: 5,
    label: 'Đã chuyển kỹ thuật'
  },
  {
    value: 3,
    label: 'Đã sửa chữa'
  },
  {
    value: 4,
    label: 'Đã trả BH'
  },
  {
    value: 6,
    label: 'KH đã phản hồi'
  }
]
export const WARRANTY_SOU = [
  {
    label: "Shoppe",
    value: 1,
  },
  {
    label: "Tiktok",
    value: 2,
  },
  {
    label: "Lazada",
    value: 3,
  },
  {
    label: "Tiki",
    value: 4,
  },
  {
    label: "Pancake",
    value: 5,
  },
  {
    label: "Temu",
    value: 6,
  },
  {
    label: "Khác",
    value: 7,
  }
];
export const WARRANTY_BOOK_PROMOTIONS = [
  {
    label: "Thông thường",
    value: 1,
  },
  {
    label: "FB +6 tháng",
    value: 2,
  },
  {
    label: "Thanh lý - BH 6 tháng - 1 đổi 1 7 ngày",
    value: 3,
  },
  {
    label: "BH 18 tháng - 1 đổi 1 3 ngày",
    value: 4,
  }
];