import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
export const CEditor = props => {
  const [defaultValue, setValue] = useState(props.value);
  const applyCallback = value => {
    setValue(value)
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: value
        }
      });
  };
  return (
    <>
      {props.readonly ?
        <div className="fake-form-control">{defaultValue || 'NA'}</div>
        :
        <Editor
          value={defaultValue}
          apiKey="317qnaajug9bxg2ixhwqrffdsxbuj5rndpo6ssma0hbd4gsi"
          init={{
            images_dataimg_filter: function(img, a, b) {
              return !img.hasAttribute('internal-blob');  // blocks the upload of <img> elements with the attribute "internal-blob".
            },
            paste_data_images: true,
            automatic_uploads: false,
            height: 500,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
          }}
          onEditorChange={applyCallback}
        />
      }
    </>
  );
};
