import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CModalFooter,
  CButton,
} from "@coreui/react";
import { CustomService } from "src/_services";
import { CopyToClipboard } from "src/_components";
import React, { Component } from "react";
import QRCode from "react-qr-code";
import { getCurrenUser } from "src/_helpers";
export class FeedbackLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      hasPopup: false,
      code: "",
      link: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.save = this.save.bind(this);
  }
  handleChange(event) {
    const { name, value, type, checked } = event.target;
    this.updateStateQuery(name, type === "checkbox" ? checked : value);
  }
  updateStateQuery(name, value) {
    var formData = { ...this.state.formData };
    formData[name] = value;
    this.setState({ formData });
  }
  closePopup() {
    this.setState({ hasPopup: false, link: null });
  }
  save() {
    var formData = this.state.formData;
    new CustomService({}, "warranty/feedback")
      .create2({
        ...formData,
        type: this.props.type,
        network_id: getCurrenUser().current_network_id,
        book_id: this.props.book_id,
      })
      .then(({ link }) => {
        this.setState({ link });
      });
  }
  render() {
    const { hasPopup, link, formData } = this.state;
    console.log(this.props.customer_name);
    return (
      <>
        <a
          onClick={() =>
            this.setState({
              hasPopup: true,
              formData: {
                customer_name: this.props.customer_name,
                customer_phone: this.props.customer_phone,
              },
            })
          }
          className="pointer"
          color="success"
        >
          Tạo link phản hồi dịch vụ
        </a>
        {hasPopup && (
          <CModal
            show={hasPopup}
            onClose={() => this.closePopup()}
            color="primary"
          >
            <CModalHeader closeButton>
              <CModalTitle>Tạo link phản hồi dịch vụ</CModalTitle>
            </CModalHeader>
            <CModalBody>
              {link != null ? (
                <div className="text-center p-3">
                  <p>
                    <CopyToClipboard>{link}</CopyToClipboard>
                  </p>
                  <p>Hoặc</p>
                  <QRCode value={link} />
                </div>
              ) : (
                <>
                  <CFormGroup row>
                    <CCol md="12">
                      <CLabel>Họ tên KH</CLabel>
                    </CCol>
                    <CCol xs="12" md="12">
                      <CInput
                        type="text"
                        name="customer_name"
                        value={formData.customer_name}
                        onChange={this.handleChange}
                        placeholder="Họ tên KH"
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="12">
                      <CLabel>Số điện thoại KH</CLabel>
                    </CCol>
                    <CCol xs="12" md="12">
                      <CInput
                        type="text"
                        name="customer_phone"
                        value={formData.customer_phone}
                        onChange={this.handleChange}
                        placeholder="Số điện thoại"
                      />
                    </CCol>
                  </CFormGroup>
                </>
              )}
            </CModalBody>
            <CModalFooter>
              {link == null ? (
                <CButton color="primary" onClick={() => this.save()}>
                  Lưu
                </CButton>
              ) : (
                <CButton color="primary" onClick={() => this.closePopup()}>
                  Đóng
                </CButton>
              )}
            </CModalFooter>
          </CModal>
        )}
      </>
    );
  }
}
