import BaseService from "./base.service";
export class CustomerService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    if (!parameters.page) parameters.page = 1;
    super("api/customer", parameters);
  }

  create(data) {
    return this.submit("post", `${this.endpoint}/`, data);
  }

  getCustomers(t) {
    return this.submit("get", this.endpoint);
  }

  getCustomer({ id, page = null }) {
    if (page) return this.submit("get", `${this.endpoint}/${id}?page=${page}`);
    return this.submit("get", `${this.endpoint}/${id}`);
  }

  getNoteByCustomerId({ id, page = 1, status }) {
    return this.submit(
      "get",
      `${this.endpoint}/note?page_size=${page}&customer_id=${id}&status=${status}&isBrowser=true&isDesktop=true&isChrome=true`
    );
  }

  getWarrantyBookList({ userId }) {
    return this.submit("get", `${this.endpoint}/${userId}/warrantybook`);
  }

  updateCustomer({ id, data }) {
    return this.submit("put", `${this.endpoint}/${id}`, data);
  }

  createNote(note) {
    return this.submit("post", `${this.endpoint}/note`, note);
  }

  updateNote(note) {
    return this.submit("put", `${this.endpoint}/note/${note.id}`, note);
  }
  destroyNote(id) {
    return this.submit("post", `${this.endpoint}/note/${id}/delete`);
  }
}
