import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import { alertActions } from './_actions';
import { history } from './_helpers';
import "react-datepicker/dist/react-datepicker.css";
import {
  Loading
} from "src/_components";
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster
} from '@coreui/react'
import './scss/style.scss';

import 'src/plugins/axios'
import i18n from 'src/i18n'
const loading = (
  <Loading/>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login'));
const Register = React.lazy(() => import('./views/pages/register'));
const InvitePage = React.lazy(() => import('./views/pages/register/InvitePage'));
const ResetPassword = React.lazy(() => import('./views/pages/login/ResetPassword'));
const ResetForm = React.lazy(() => import('./views/pages/login/ResetForm'));
const ConfirmPage = React.lazy(() => import('./views/pages/register/Confirm'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Components = React.lazy(() => import('./views/pages/Components'));
const PrivacyPolicy = React.lazy(() => import('./views/pages/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./views/pages/TermsOfService'));
// const WarrantyDetail = React.lazy(() => import('src/views/pages/network/page/WarrantyDetail'));
class App extends Component {
  constructor(props) {
    super(props);
    history.listen(() => {
      // this.props.clearAlerts()
    })
  }
  render() {
    const { alert } = this.props;
    return (
      <HashRouter>
          <CToaster position={'top-right'}>
            {
              alert.map((toast, key)=>{
                return(
                  <CToast
                    key={'toast' + key}
                    show={true}
                    autohide={5000}
                    fade={true}
                    className={toast.type}
                  >
                    <CToastHeader closeButton={true}>
                    {i18n.t('Thông báo')}
                    </CToastHeader>
                    <CToastBody>
                      {toast.message}
                    </CToastBody>
                  </CToast>
                )
              })
            }
          </CToaster>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/reset-password" name="ResetPassword Page" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/reset/:id" name="ResetPassword Page" render={props => <ResetForm {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/invite/:code" name="invite Page" render={props => <InvitePage {...props}/>} />
              <Route exact path="/confirm/:id" name="Confirm Page" render={props => <ConfirmPage {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/components" name="Page 500" render={props => <Components {...props}/>} />
              <Route exact path="/privacy-policy" name="Privacy Policy" render={props => <PrivacyPolicy {...props}/>} />
              <Route exact path="/terms-of-service" name="Terms Of Service" render={props => <TermsOfService {...props}/>} />
              {localStorage.getItem('Token') ?
              <>
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              <Route path="/_-_" name="Home" render={props => <TheLayout {...props}/>} />
              </>
              :
              <>
              <Route path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="/_-_" name="Login Page" render={props => <Login {...props}/>} />
              </>
              }
              <Route path="*" name="Page 404" render={props => <Page404 {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}
function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};
const connectedApp = connect(mapState, actionCreators)(App);
export default connectedApp;
//withTranslation()(connectedApp);
