/* eslint-disable no-useless-escape */
import clsx from "clsx";
import { Loading } from "src/_components";
export const Avata = (props) => {
  var src = props.src
  if (src && (!src.includes('http:/\/') && !src.includes('https:/\/'))) {
    src = process.env.REACT_APP_API_URL + src
  }
  return (
    <div
      className={clsx({
        "a-avatar": true,
      })}
      {...props}
    >
      <div className="image-loading position-center">
        <Loading type="ring" />
      </div>
      <img
        className={clsx({
          "position-center": true,
        })}
        src={src || "/static/images/no-img.png"}
        alt=""
      />
      <img
        className={clsx({
          "image-placeholder": true,
        })}
        src={"/static/images/no-img.png"}
        alt=""
      />
    </div>
  );
};
