import BaseService from './base.service'
import Axios from 'axios'
export class ReportService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/warranty/report', parameters)
  }
  downloadFile (type) {
    this.loading();
    Axios({
        url: `${this.endpoint}/${type}`+ this.getParameterString(), //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        console.log(response)
        // create file link in browser's memory
        this.loadingEnd();
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${type}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
    // return new Promise((resolve, reject) => {
    //   Axios['get'](`${this.endpoint}/${type}`+ this.getParameterString(), {}, {
    //     responseType: 'blob'
    //   })
    //     .then(response => {
    //       const href = URL.createObjectURL(response.data);
    //       // create "a" HTML element with href to file & click
    //       const link = document.createElement('a');
    //       link.href = href;
    //       link.setAttribute('download', 'file.pdf'); //or any other extension
    //       document.body.appendChild(link);
    //       link.click();

    //       // clean up "a" element & remove ObjectURL
    //       document.body.removeChild(link);
    //       URL.revokeObjectURL(href);
    //     })
    //     .catch(e => {
    //       this.loadingEnd();
    //       this.handleErrorResponse(e);
    //       reject(null);
    //     });
    // });
  }
}