import BaseService from "./base.service";
import Axios from 'axios'

export class ImportFileService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}, endpointt) {
    super(`api/${endpointt}`, parameters);
  }
  downloadFile () {
    this.loading();
    return new Promise((resolve, reject) => {
      Axios['get'](`${this.endpoint}`+ this.getParameterString(), {}, {
        useCredentails: true,
        // responseType: 'blob',
        // observe: 'response',
        // headers: {
        //   "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // }
      })
        .then(response => {
          this.loadingEnd();
          console.log(response.data)
          resolve(response.data);
        })
        .catch(e => {
          this.loadingEnd();
          this.handleErrorResponse(e);
          reject(null);
        });
    });
  }
  upload(file, data) {
    this.loading();
    let formData = new FormData();
    formData.append('file', file);
    if (data) {
      for (var i = 0; i < Object.keys(data).length; i++) {
        formData.append(Object.keys(data)[i], data[Object.keys(data)[i]]);
      }
    }
    return new Promise((resolve, reject) => {
      Axios['post'](`${this.endpoint}`+ this.getParameterString(), formData, {
        useCredentails: true,
        responseType: 'blob',
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.loadingEnd();
          console.log(response)
          resolve(response.data);
        })
        .catch(e => {
          console.log(e)
          this.loadingEnd();
          this.handleErrorResponse(e);
          reject(null);
        });
    });
  }
}
