/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export class VCarouselV2 extends Component {
  render() {
    var { items } = this.props
    if (!items) return ''
    items = (items || []).map(item => {
      if (item && (!item.includes('http:/\/') && !item.includes('https:/\/'))) {
        item = process.env.REACT_APP_API_URL + item
      }
      return item
    })
    return (
      <Carousel className="v-carousel" dynamicHeight={true} showThumbs={items.length > 1} showIndicators={items.length > 1}>
        {items.map(item => {
          return (<div key={item}>
            <img src={item} key={item} alt="" />
          </div>)
        })}
      </Carousel>
    );
  }
}
