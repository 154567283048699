import BaseService from "./base.service";
export class WarrantyService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("api/warranty", parameters);
  }
  sendWarranty(data, type) {
    if (data.id)
      return this.submit("post", `${this.endpoint}/${type}/${data.id}`, data);
    return this.submit("post", `${this.endpoint}/${type}`, data);
  }
  removeWarranty(id, type) {
    return this.submit("post", `${this.endpoint}/${type}/${id}/delete`);
  }
  hideWarranty(id) {
    return this.submit("post", `${this.endpoint}/${id}/hidden`);
  }

  updateActiveWarranty(id, item) {
    return this.submit("put", `${this.endpoint}/${id}/update_active`, item);
  }
}
