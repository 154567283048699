/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export class VCarousel extends Component {
  render() {
    const { items } = this.props
    if (!items) return ''
    return (
      <Carousel className="v-carousel" dynamicHeight={true} showThumbs={items.length > 1} showIndicators={items.length > 1}>
        {items.map((item, ind) => {
          return (<div key={ind}>
            <img src={item.src} key={item.src} alt="" />
          </div>)
        })}
      </Carousel>
    );
  }
}
